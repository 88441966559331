// @ts-expect-error not typed
import { api } from '@/assets/js/api';

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function validateEmail(email: ValidEmailFormat): ValidEmailFormat | false {
    if (typeof email === 'string') {
        return emailRegex.test(email) ? email : false;
    }

    if ('email' in email && typeof email.email === 'string') {
        if (emailRegex.test(email.email)) {
            // De-duplicate email/name combo
            if (email.name && email.email === email.name) {
                return email.email;
            }
            return email;
        }
        return false;
    }

    if ('user' in email && typeof email.user === 'number') {
        return email;
    }

    return false;
}

export function getValidRecipients(list: ValidEmailFormat[] = []) {
    if (!Array.isArray(list)) { return []; }

    const validated = list.map(validateEmail).filter((it) => it);
    return validated as ValidEmailFormat[];
}

function validateAttachment(attachment: EmailAttachment): EmailAttachment | false {
    function validatePath(path: string) {
        return typeof path === 'string' && path.startsWith('http');
    }

    if (typeof attachment === 'string') {
        return validatePath(attachment) ? attachment : false;
    }

    if ('link' in attachment && validatePath(attachment.link)) {
        return attachment;
    }

    return false;
}

function getValidAttachments(list: EmailAttachment[] = []) {
    if (!Array.isArray(list)) { return []; }

    const validated = list.map(validateAttachment).filter((it) => it);
    return validated as EmailAttachment[];
}

export const defaultData: SendEmail = {
    to: [],
    bcc: [],
    from: undefined,
    reply_to: undefined,
    subject: '',
    attachments: [],
    template: {
        path: '',
        data: {},
    },
    message: '',
    event: undefined,
    lang: 'nl',
    send_messages_separately: false,
    timestamp_execute: undefined,
};

export async function sendEmail({
    to,
    bcc,
    from,
    reply_to,
    subject,
    attachments,
    template,
    message,
    event,
    eventID,
    lang,
    send_messages_separately,
    timestamp_execute,
}: {
    to: ValidEmailFormat[];
    bcc?: ValidEmailFormat[];
    from?: string; // name
    reply_to?: ValidEmailFormat;
    subject: string;
    attachments?: string[] | { link: string, filename: string }[];
    template?: string | { path: string, data: object };
    message: string; // allows html
    event?: {
        linked_table: string
        linked_item: number
    };
    eventID?: number;
    lang?: string;
    send_messages_separately?: boolean; // default = true
    timestamp_execute?: number
}) {
    const email: SendEmail = JSON.parse(JSON.stringify(defaultData));

    // Recipients
    email.to = getValidRecipients(to);
    email.bcc = getValidRecipients(bcc);
    if (timestamp_execute) {
        email.timestamp_execute = timestamp_execute
    }

    if (!email.to.length && !email.bcc.length) {
        throw new Error('no-valid-recipients');
    }
    // Sender
    if (from && typeof from === 'string') {
        email.from = from;
    }
    if (reply_to) {
        const replyData = validateEmail(reply_to);
        if (replyData) {
            email.reply_to = replyData;
        } else {
            throw new Error('invalid-replyto');
        }
    }

    // Contents
    if (lang) {
        email.lang = lang;
    }

    if (template) {
        if (typeof template === 'string') {
            email.template = { path: template, data: {} };
        } else if (template.path && typeof template.path === 'string') {
            email.template = template;
        }
    }

    if (!subject || typeof subject !== 'string') {
        throw new Error('no-subject');
    }
    email.subject = subject;

    if (!message || typeof message !== 'string') {
        throw new Error('no-message');
    }
    email.message = message;

    if (attachments) {
        email.attachments = getValidAttachments(attachments);
    }
    if (typeof send_messages_separately === 'boolean') {
        email.send_messages_separately = send_messages_separately;
    }

    // Event
    if (typeof eventID === 'number') {
        email.event = eventID;
    } else if (typeof event?.linked_item === 'number') {
        email.event = event
    }

    return api.post({
        name: `send_email_${Math.ceil(Math.random() * 1000)}`,
        endpoint: 'email',
        data: email,
        loading: true,
    });
}
