import { api } from '@/assets/js/api'

const CU_MAX = 864;
const custom_client = {
    Editpane: {
        methods: {
            validateMux(data) {
                return new Promise((resolve, reject) => {
                    if ((data.item.services_cu?.cu || 0) > CU_MAX) {
                        alert('The combined CU of the services exceeds the maximum of ' + CU_MAX);
                        reject();
                    }
                    resolve(data);
                })
            },

            async saveMux(data) {
                this.$store.state.app.loading_fullscreen.show = true;

                const response = await api.post({
                    name: 'SaveMux',
                    endpoint: 'src/mixins/mux.theradiohub.com/Mux_UpdateServer.php',
                    data: {
                        id: data.item.id,
                        data: data.item
                    },
                }).catch((e) => {
                    if (typeof e.data === 'string' && e.data.startsWith('{')) {
                        const eresponse = JSON.parse(e.data);
                        if (eresponse.message) {
                            return {
                                data: {
                                    status: 'error',
                                    error: 'Error updating mux',
                                    error_responses: [
                                        {
                                            status: 'error',
                                            error: eresponse.message
                                        }
                                    ]
                                }
                            }
                        }
                    }
                }).finally(() => {
                    this.$store.state.app.loading_fullscreen.show = false;
                })

                if (response.data.status !== 'success') {
                    if (response.data.error_responses) {
                        response.data.error_responses.forEach((error) => {
                            this.$toast(error.error, { classes: 'bg-danger' });
                        })
                    } else {
                        this.$toast('Saving multiplex went wrong', { classes: 'bg-danger' });
                    }
                    throw new Error("Something went wrong");
                }


                return data;
            }
        },
    },
};

export default custom_client;
