import { editChoiceItems } from '@/components/checklist/mixins/choiceitems'
import { sendEmail } from '@/services/email';

const custom_client = {
    default: {
        methods: {
            editChoiceItems(item, settings) {
                const choices = item.data.mainItem.choiceitems || [];
                const line = item.data.item;

                this.laurensEditChoiceItems(line, {
                    choices,
                    mode: 'edit'
                })
            },

            laurensEditChoiceItems: editChoiceItems,
        },
    },

    Editpane: {
        methods: {
            async sendNoshowEmail({ data, originalData }) {
                const recipients = data.form.to.map((it) => ({ name: it.name, email: it.email }));
                const { subscribers, images, choiceitems, ...activity } = data.form.data.activity;
                const email = {
                    bcc: recipients,
                    subject: data.form.title,
                    lang: 'nl',
                    template: {
                        path: 'message.mjml',
                        data: {
                            data: {
                                activity,
                                title: data.form.title,
                                text: data.form.text
                            }
                        }
                    },
                    event: {
                        linked_table: 'activities',
                        linked_item: originalData.item.id
                    },
                    message: '-',
                }

                sendEmail(email)
            },
        }
    }
}

export default custom_client
