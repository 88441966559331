import {
    quotesGetDays, getStaggeredDiscount, quotesStaggeredRecalc, quotesStaggeredCalcOnProductChange,
} from '@/mixins/staggeredDiscounts'
import onRepairComplete from '@/mixins/repairs/repairMixin'
import { recursiveChildFunction } from '@/services/helpers-ts'
import debounce from 'lodash.debounce'

import { setChoiceItems, editChoiceItems } from '@/components/checklist/mixins/choiceitems'
import { setChecklistItems } from '@/components/checklist/mixins/data'
import { postEvent } from '@/services/events'
import { api } from '@/assets/js/api'
import { sendEmail } from '@/services/email';

function replaceParams(string, params) {
    if (!string || typeof string !== 'string') { return '' }
    params.forEach(param => {
        string = string.replace(param.key, param.value)
    })
    return string
}

const periodChangeCache = {}

function getChecklistCount(checklist) {
    let count = 0
    recursiveChildFunction(checklist, 'items', (item) => {
        if (item.amount) {
            count += item.amount
        }
    })
    return count
}

const custom_client = {
    default: {
        methods: {
            editChoiceItems(item, settings) {
                return this.maloneyEditChoiceItems(item, { ...settings, maxPerGroup: 1 })
                // the custom function is neccessary to bind the 'this.$set' function in choiceitems.
            },
            maloneyEditChoiceItems: editChoiceItems,
        },
    },

    Periods: {
        mounted() {
            this.$on('action', this.onUpdatePeriodDates)
        },

        beforeDestroy() {
            this.$off('action', this.onUpdatePeriodDates)
        },

        methods: {
            onUpdatePeriodDates({ method = '', payload = {} } = {}) {
                if (method === 'onUpdateData' && payload.store && payload.key === 'periodDates') {
                    let largest
                    let smallest
                    Object.values(payload.data).forEach((dates) => {
                        const splitDates = dates.split('-')
                        splitDates.forEach((date) => {
                            if (+date > largest || !largest) {
                                largest = +date
                            }
                            if (+date < smallest || !smallest) {
                                smallest = +date
                            }
                        })
                    })
                    this.item.pickup = smallest
                    this.item.return = largest
                }
            },
        },
    },

    Rules: {
        mounted() {
            this.$on('changed', this.onRuleChanged)
            this.$on('action', this.onUpdatePeriodDates)
            setTimeout(() => {
                this.countChecklistItems()
            }, 1000)
        },

        beforeDestroy() {
            this.$off('changed', this.onRuleChanged)
            this.$off('action', this.onUpdatePeriodDates)
        },

        methods: {
            countChecklistItems: debounce(function debounced() {
                if (this.config.api.table !== 'bookings') { return }
                const groups = this.item.bookings_lines || []
                const count = groups.reduce((acc, group) => {
                    acc += group.items.reduce((iacc, item) => {
                        const itemDays = item.days || 1
                        const checklistCount = getChecklistCount(item.bookings_lines_checklists) || 0
                        return iacc + (itemDays * checklistCount)
                    }, 0)
                    return acc
                }, 0)
                this.$set(this.item, 'checklistitem_count', count)
            }, 5000),

            onRuleChanged(payload = {}) {
                // TODO rewrite to hook
                if (this.config.api.table !== 'bookings') { return }

                if (payload.action === 'add-line' && !payload.item.period) {
                    if (this.item.bookings_periods.length) {
                        const lastPeriod = this.item.bookings_periods[
                            this.item.bookings_periods.length - 1
                        ]
                        this.$set(payload.item, 'period', lastPeriod.id)
                        this.$set(payload.item, 'days', lastPeriod.invoice_days)
                        this.$set(payload.item, 'volume_discount', this.getStaggeredDiscount(lastPeriod.invoice_days))
                    }
                }

                this.countChecklistItems()

                // if (payload.action === 'change-line' && payload.type === 'period' && payload.val && payload.oldVal) {
                //     const lowest = Math.min(payload.val, payload.oldVal);
                //     const highest = Math.max(payload.val, payload.oldVal);
                //     const key = `${payload.itemid}-${lowest}-${highest}`;
                //     const timeLimit = 2; // seconds
                //     const now = new Date().getTime();
                //     if (!periodChangeCache[key] || periodChangeCache[key] < now - (timeLimit * 1000)) {
                //         const oldPeriod = this.item.bookings_periods.find((it) => it.id === payload.oldVal);
                //         if (oldPeriod.scan_state === 0) { // period not scanned. allow
                //             return;
                //         }

                //         const totalOut = (payload.item.scanState?.outNr || 0) + (payload.item.scanState?.outNrTree || 0);
                //         if (totalOut === 0) { // item not scanned. Allow
                //             return;
                //         }

                //         const newPeriod = this.item.bookings_periods.find((it) => it.id === payload.val);
                //         if (newPeriod.return < oldPeriod.return) { // new period is shorter. allow
                //             return;
                //         }

                //         this.$toast('Dit item mag alleen naar een kortere periode verplaatst worden.', { classes: 'bg-danger' });
                //         periodChangeCache[key] = now;
                //         this.$set(payload.item, 'period', payload.oldVal);
                //     }
                // }
            },

            getStaggeredDiscount,

            setChecklistItems,

            onUpdatePeriodDates({ method = '', payload = {} } = {}) {
                if (method === 'onUpdateData' && payload.store && payload.key === 'periodDates') {
                    let largest
                    let smallest
                    Object.values(payload.data).forEach((dates) => {
                        const splitDates = dates.split('-')
                        splitDates.forEach((date) => {
                            if (+date > largest || !largest) {
                                largest = +date
                            }
                            if (+date < smallest || !smallest) {
                                smallest = +date
                            }
                        })
                    })
                    this.item.pickup = smallest
                    this.item.return = largest

                    this.countChecklistItems()
                }
            },
        },
    },

    Fields: {
        methods: {
            onUpdateBookingsLinesDays({ value, data, trigger }) {
                this.$set(data.item, 'days', value.value || 0)
                this.quotesStaggeredRecalc({
                    value,
                    data,
                    field: { id: 'days' },
                    trigger,
                })
            },

            onUpdateTodoStartTime({ value, data }) {
                if (value) {
                    const newEndTime = value + 3600
                    if (!data.item.end_time || data.item.end_time < newEndTime) {
                        this.$set(data.item, 'end_time', newEndTime)
                    }
                }
            },

            quotesGetDays,
            getStaggeredDiscount,
            quotesStaggeredRecalc,
            quotesStaggeredCalcOnProductChange,
            setChecklistItems,
            setChoiceItems,
        },
    },

    Editpane: {
        methods: {
            onRepairComplete,

            showWarningForMissingPeriods(data) {
                return new Promise((resolve, reject) => {
                    if ((data.item.bookings_lines[0]?.items || []).some((it) => it.itemid && !it.period && !it.is_purchaseitem)) {
                        this.$modal({
                            id: 'confirm',
                            path: 'Confirm',
                            title: this.$t('periods.lines_without'),
                            data: {
                                text: this.$t('periods.lines_without_text'),
                                buttonText1: this.$t('actions.edit'),
                                buttonText2: this.$t('actions.save'),
                                buttonClass1: 'bg-success',
                                button2Resolve: true,
                                buttonShortcuts1: ['n', 'esc'],
                                buttonShortcuts2: ['y', 'enter'],
                            },
                        })
                            .then(() => {
                                resolve(data)
                            })
                            .catch(reject)
                    } else {
                        resolve(data)
                    }
                })
            },

            async sendWhatsappMessage({ data }) {
                if (!data.form.hsm.name) { return false }
                if (!data.params.hsm_id) {
                    return true
                }
                // Send message to trengo
                const headers = {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMGUwNGVlYTQ5NWIwZDk3ZTg1NzQwMGI2NmRkYTU5NWIzODI5NGU2ZDVhY2VmYjU2NzQwODI4Y2EyYjRmZDdhYTk0NjdjZGQzY2NhNjYwNWMiLCJpYXQiOjE2Nzk5Mjc4NjQuMDIyMzI3LCJuYmYiOjE2Nzk5Mjc4NjQuMDIyMzMxLCJleHAiOjQ4MDQwNjkwNjQuMDEzMTA1LCJzdWIiOiIyMTQwNjUiLCJzY29wZXMiOltdfQ.aNYUmFPp6UzNgaeuBh_2ftTUwHHz4OZEWpt8odsKf36SU61phXextl4zWuMiFvQFlcGMEzec_nZ8fHO6KzcAlA',
                }

                data.params.params = data.params.params.filter((it) => it.value)

                await api.post({
                    name: 'SendWhatsappMessage',
                    url: 'https://app.trengo.com/api/v2/wa_sessions',
                    headers,
                    headers_replace: true,
                    data: data.params,
                })

                // Post event
                const user = this.$store.getters['Auth/user']
                const event = {
                    type: 'message_send',
                    trigger_type: 'blixem',
                    trigger_data: {
                        type: 'Whatsapp button'
                    },
                    name: data.form.hsm.name,
                    linked_table: this.paneConfig.api.table,
                    linked_item: this.item.id,
                    data: {
                        channel: 'Whatsapp',
                        recipients: [{ name: data.form.to, to: data.form.nr }],
                        message: `<p>Via Trengo: ${data.form.hsm.name} (${data.form.hsm?.id || data.params.hsm_id})<\/p><p>${replaceParams(data.form?.hsm?.example || '', data.params.params)}<\/p>`,
                    },
                    user_id: user.id,
                    user_name: user.name,
                    user_avatar: user.data.avatar,
                    user_table: 'users',
                }
                await postEvent(event)
            },

            async sendCouponByEmail({ data, originalData }) {
                const recipients = data.form.to
                const { valid_clients, ...coupon } = originalData.item

                recipients.forEach((it) => {
                    sendEmail({
                        to: [it],
                        subject: '{{ translations.emails.loyalty_levelup.coupon_title }}',
                        lang: it.lang || 'nl',
                        template: {
                            path: 'coupon.mjml',
                            data: {
                                data: {
                                    client: {
                                        name: it.name,
                                    },
                                    coupon,
                                },
                            },
                        },
                        event: {
                            linked_table: 'coupons',
                            linked_item: coupon.id,
                        },
                        message: '-',
                    });
                })

            },
        },
    },
}

export default custom_client
